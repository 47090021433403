import classNames from 'classnames/bind';

import SmartImage from '@/components/image/SmartImage';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    hasGradient?: boolean;
    size?: 'sm' | 'md' | 'lg';
    priority?: boolean;
}

const Device: React.FC<Props> = ({
    hasGradient = true,
    size,
    priority = false,
}) => {
    return (
        <div className={cx('__device-container', `--${size}`)}>
            <div className={cx('__device-size', 'device device-iphone-x')}>
                <div className={cx('__device-frame-size', 'device-frame')}>
                    <SmartImage
                        imgClassName={cx(
                            '__device-screen-size',
                            'device-screen'
                        )}
                        src='/images/mobile/discover_festivals.png'
                        alt='Discover'
                        width={0}
                        height={0}
                        sizes='100vw'
                        priority={priority}
                    />
                </div>
                <div
                    className={cx(
                        '__device-stripe-size',
                        'device-stripe'
                    )}></div>
                <div
                    className={cx(
                        '__device-header-size',
                        'device-header'
                    )}></div>
                <div
                    className={cx(
                        '__device-sensors-size',
                        'device-sensors'
                    )}></div>
            </div>
            {hasGradient ? <div className={cx('__gradient')}></div> : null}
        </div>
    );
};

export default Device;

import classNames from 'classnames/bind';

import Tile from './Tile';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    festivals: Festival[];
    hideMeta: boolean;
    interactive: boolean;
    limit: number;
}

const Masonry = ({ festivals = [], hideMeta, interactive, limit }: Props) => {
    return (
        <div
            className={cx(
                '__masonry_container',
                `--has-${festivals.length}-festivals`,
                'custom__scrollbar'
            )}>
            {festivals.length
                ? festivals?.map((f: Festival, idx) => (
                      <Tile
                          festival={f}
                          key={`festival-masonry-tile-${f.slug}-${idx}`}
                          hideMeta={hideMeta}
                          interactive={interactive}
                      />
                  ))
                : new Array(limit)
                      .fill('s')
                      .map((a, idx) => (
                          <Tile
                              key={`festival-masonry-tile-${idx}`}
                              hideMeta
                              loading
                          />
                      ))}
        </div>
    );
};

export default Masonry;

// TODO: fetch when in focus
import { useEffect, useRef, useState } from 'react';

import $http from '@/utils/http';

type SuccessData = Record<string, unknown>;

interface Props {
    request?: Omit<HTTPRequestProps, 'transformResponse'>;
    requests?: Omit<HTTPRequestProps, 'transformResponse'>[];
    method: 'get' | 'post';
    onError?: (error: Error) => void;
    onSuccess?: (data: SuccessData | SuccessData[]) => void;
}

export interface UseHTTPResponse {
    loading: boolean;
    data: any;
    error: any;
    refetch: () => Promise<void>;
}

const useHTTP = ({
    request,
    requests,
    method,
    onError,
    onSuccess,
}: Props): UseHTTPResponse => {
    const httpQueue = useRef<string[]>([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState<SuccessData | SuccessData[]>();

    const refetch = async () => {
        const queue = httpQueue.current;

        try {
            setLoading(true);

            const wait: Promise<unknown>[] = [];

            if (queue.length) {
                if (queue.length === 1) {
                    const httpConfig = JSON.parse(queue[0]);
                    const resp = await $http[httpConfig.method](
                        httpConfig.request
                    );
                    setData(resp);
                } else if (queue.length > 1) {
                    for (const config of queue) {
                        wait.push(
                            new Promise(async (resolve, reject) => {
                                try {
                                    const httpConfig = JSON.parse(config);
                                    const resp = await $http[httpConfig.method](
                                        httpConfig.request
                                    );

                                    resolve(resp);
                                } catch (err) {
                                    reject(err);
                                }
                            })
                        );
                    }

                    const allData: any = await Promise.all(wait);
                    setData(allData);
                }
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let queue = httpQueue.current;

        if (queue.length) return;

        if (request) {
            const key = JSON.stringify({ method, request });
            const queue = httpQueue.current;

            (async () => {
                try {
                    if (!queue[key]) {
                        setLoading(true);
                        queue.push(key);

                        const resp = await $http[method](request);
                        if (onSuccess) onSuccess(resp);

                        setData(resp);
                    }
                } catch (err) {
                    if (onError) onError(err);
                    setError(err);
                } finally {
                    setLoading(false);
                }
            })();
        }

        if (requests) {
            const wait: Promise<unknown>[] = [];

            (async () => {
                try {
                    setLoading(true);

                    for (const r of requests) {
                        const key = JSON.stringify({ method, request });

                        if (!queue[key]) {
                            queue.push(key);

                            wait.push(
                                new Promise(async (resolve, reject) => {
                                    try {
                                        const resp = await $http[method](r);
                                        resolve(resp);
                                    } catch (err) {
                                        reject(err);
                                    }
                                })
                            );
                        }
                    }

                    const allData: any = await Promise.all(wait);
                    setData(allData);
                    if (onSuccess) onSuccess(allData);
                } catch (err) {
                    if (onError) onError(err);
                    setError(err);
                } finally {
                    setLoading(false);
                }
            })();
        }

        return () => {
            queue = [];
        };
    }, [request, requests, method, onError, onSuccess]);

    return {
        loading,
        data,
        error,
        refetch,
    };
};

export default useHTTP;

import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';

import Button from '@/components/form/button/Button';

import styles from './styles.module.scss';
import Icon, { IconSize, IconType } from '@/components/icon/Icon';
import PageHeader from '@/components/typography/page-header/PageHeader';

const cx = classNames.bind(styles);

interface Props {
    heading: string;
    pageHeader?: boolean;
    icon?: IconType;
    iconRight?: IconType;
    iconRightExpanded?: IconType;
    subHeading?: string;
    children: ReactNode;
}

const Disclosure: React.FC<Props> = ({
    heading,
    pageHeader,
    icon,
    iconRight,
    iconRightExpanded,
    subHeading,
    children,
}) => {
    const router = useRouter();
    const [expanded, setIsExpanded] = useState(false);

    const onTriggerHandler = () => {
        if (!expanded) {
            setIsExpanded(true);
        } else {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        const handleRouteChange = () => {
            setIsExpanded(false);
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router.events]);

    return (
        <div
            className={cx(
                'disclosure',
                { '--has-pageheader': pageHeader },
                { '--expanded': expanded },
                { '--collapsed': !expanded }
            )}>
            <div className={cx('__trigger')}>
                <Button
                    type='button'
                    paddingHorizonal={false}
                    theme='transparent'
                    wrap={false}
                    onPress={onTriggerHandler}
                    size='large'
                    darkMode>
                    <div className={cx('__heading-container')}>
                        {icon ? (
                            <div className={cx('__icon', '--left')}>
                                <Icon name={icon} size={IconSize.Tiny} />
                            </div>
                        ) : null}
                        <div className={cx('__text')}>
                            {!pageHeader ? (
                                <p className={cx('__heading')}>{heading}</p>
                            ) : (
                                <PageHeader h1={heading} />
                            )}
                            {subHeading ? (
                                <p className={cx('__sub-heading')}>
                                    {subHeading}
                                </p>
                            ) : null}
                        </div>
                        <div className={cx('__icon')}>
                            {!expanded ? (
                                <Icon
                                    name={
                                        !iconRight
                                            ? IconType.ChevronDown
                                            : iconRight
                                    }
                                    size={IconSize.Medium}
                                />
                            ) : (
                                <Icon
                                    name={
                                        !iconRightExpanded
                                            ? IconType.ChevronUp
                                            : iconRightExpanded
                                    }
                                    size={IconSize.Medium}
                                />
                            )}
                        </div>
                    </div>
                </Button>
            </div>
            <div className={cx('__content')}>{children}</div>
        </div>
    );
};

export default Disclosure;

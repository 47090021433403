import classNames from 'classnames/bind';

import Tile from './Tile';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    festivals: Festival[];
    hideMeta: boolean;
    interactive: boolean;
}

const Row = ({ festivals, hideMeta, interactive }: Props) => {
    return (
        <div className={cx('__row_container')}>
            {festivals?.map((f: Festival, idx) => (
                <Tile
                    festival={f}
                    key={`festival-row-tile-${f.slug}-${idx}`}
                    hideMeta={hideMeta}
                    interactive={interactive}
                />
            ))}
        </div>
    );
};

export default Row;

import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import Link from 'next/link';

import A11yButton from '@/components/a11y-button/A11yButton';
import More from '@/components/more/More';
import Pill from '@/components/pill/Pill';
import SmartImage from '@/components/image/SmartImage';
import Skeleton from '@/components/loading/skeleton/Skeleton';
import { getFormattedDate, makeSlug } from '@/utils/helper';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    festival?: Festival;
    hideMeta: boolean;
    interactive?: boolean;
    loading?: boolean;
}

const Tile = ({
    festival,
    hideMeta,
    interactive = true,
    loading = false,
}: Props) => {
    const router = useRouter();

    const getDate = () => getFormattedDate(festival?.upcomingDate);
    const onClickHandler = () => router.push(`/festivals/${festival?.slug}`);

    const Content = () => {
        if (!festival) return;

        return (
            <>
                <div className={cx('__img_container')}>
                    <SmartImage
                        src={`${process.env.NEXT_PUBLIC_CDN_URL}${festival.thumbnail?.url}`}
                        alt={festival.name}
                        width={0}
                        height={0}
                        quality={60}
                        sizes='100vw'
                    />
                </div>
                {!hideMeta ? (
                    <div className={cx('__meta')}>
                        <div className={cx('__text')}>
                            <div className={cx('__festival_name')}>
                                <p className={cx('__label')}>{festival.name}</p>
                            </div>
                            {festival.venue?.city &&
                            festival.venue?.country?.name ? (
                                <div className={cx('__festival_venue')}>
                                    <Link
                                        href={`/locations/${festival.venue.country?.slug}/${makeSlug(festival.venue.city)}`}
                                        className='--underline'>
                                        {festival.venue.city}
                                    </Link>
                                    ,&nbsp;
                                    <Link
                                        href={`/locations/${festival.venue.country?.slug}`}
                                        className='--underline'>
                                        {festival.venue.country.name}
                                    </Link>
                                </div>
                            ) : null}
                            <div className={cx('__festival_date')}>
                                {getDate()}
                            </div>
                            {festival?.genres ? (
                                <div className={cx('__festival_tags')}>
                                    <More
                                        ariaLabel='View more related genres'
                                        buttonLabel='Related genres'
                                        title='Related Genres'>
                                        {festival.genres.map((g) => (
                                            <div
                                                key={`masonry-festival-${festival.id}-tag-${g.slug}`}
                                                className={cx(
                                                    '__festival_tag'
                                                )}>
                                                <Pill
                                                    label={g.name}
                                                    size='tiny'
                                                    theme='black'
                                                    href={`/festivals/genre/${g.slug}`}
                                                />
                                            </div>
                                        ))}
                                    </More>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </>
        );
    };

    if (loading) {
        return (
            <div className={cx('__tile', '--loading')}>
                <Skeleton
                    theme='light'
                    bones={[{ width: '100%', height: '100%' }]}
                />
            </div>
        );
    }

    return interactive ? (
        <div className={cx('__tile')}>
            <A11yButton>
                <div className={cx('__content')}>
                    <Content />
                    <button
                        onClick={onClickHandler}
                        className='sr-only'
                        data-expand-click-area>
                        {`See festival's details`}
                    </button>
                </div>
            </A11yButton>
        </div>
    ) : (
        <div className={cx('__tile')}>
            <Content />
        </div>
    );
};

export default Tile;
